import L from 'leaflet'

const mapUrl = 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'

const directory = `${import.meta.env.VITE_APP_URL}/vendor/leaflet/images`
const iconUrl = `${directory}/marker-icon.png`
const iconRetinaUrl = `${directory}/marker-icon-2x.png`
const shadowUrl = `${directory}/marker-shadow.png`

L.Marker.prototype.options.icon = L.icon({
    iconUrl,
    shadowUrl,
    iconRetinaUrl,
    iconSize: [25, 41],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
    tooltipAnchor: [16, -28],
    shadowSize: [41, 41]
})

window.allMaps = []
window.Maps = []

window.addEventListener('loadMaps', () => {
    setTimeout(() => {
        window.allMaps.forEach(map => {
            map._onResize()
        })
    }, 100)
})

const getTileLayer = () => new L.TileLayer(mapUrl)

window.AddServiceMaps = (services) => {
    services.map((service) => {
        if (!window.Maps.find(id => id == service.id)) {
            const mapId = 'map-' + service.id
            const el = document.getElementById(mapId)
            if (el) {
                let layer = getTileLayer()
                let map = L.map(mapId, {attributionControl: false})
                L.marker([service.lat, service.lng]).addTo(map)
                map.setView([service.lat, service.lng], 14)
                map.addLayer(layer)
                window.allMaps.push(map)
                window.Maps.push(service.id)
            }
        }
    })
}

window.SetCemeteryMap = (lat, lng) => {
    const cemetery = 'cemetery-map'
    const el = document.getElementById(cemetery)
    if (el) {
        const layer_cemetery = getTileLayer()
        const map_cemetery = L.map(cemetery, {attributionControl: false})
        L.marker([lat, lng]).addTo(map_cemetery)
        map_cemetery.setView([lat, lng], 16)
        map_cemetery.addLayer(layer_cemetery)
    }
}
